
import Vue from "vue";
import { defineComponent } from "vue";
import Api from "@/services/Api";
import { TableOptions } from "@/models/TableOptions";
import Axios, { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import moment, { max } from "moment";
import { Snackbar } from "@/lib/Snackbar";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
interface DataItem {
    periodDate: string;
    amountDay: string;
    debitAmount: string;
    creditAmount: string;
}
export default defineComponent({
    components: {
        apexchart: VueApexCharts,
    },
    data() {

        return {
            menuInitialDate: false,
            menuFinalDate: false,

            startPeriod: moment().startOf('month').format("YYYY-MM-DD"),
            endPeriod: moment().add(-1, 'd').format("YYYY-MM-DD"),
            defaultStartPeriod: moment().startOf('month').format("YYYY-MM-DD"),
            defaultEndPeriod: moment().format("YYYY-MM-DD"),
            maxDate: moment().add(-1, 'd').format("YYYY-MM-DD"),
            lang: navigator.language,
            moment: moment,
            errors: [] as any,
            loadingDesserts: false,
            desserts: [] as any,
            hasData: false,
            chartOptionsLines: {
                chartSeries: [] as any,
                colors: ["#42A5F5", "#E57373"],
                chart: {

                    type: "area",
                    toolbar: { show: false },
                },
                dataLabels: {
                    enabled: false,
                },

                stroke: {
                    curve: "smooth",
                },
                xaxis: {
                    type: "date",
                    categories: [moment().format("YYYY-MM-DD")],
                },
                tooltip: {
                    y: {
                        formatter: (value: number) =>
                            new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                            }).format(value),
                    },
                },
            },
            chartOptionsDonut: {
                chart: {
                    type: "donut",
                    toolbar: { show: false },
                },
                colors: ["#42A5F5", "#E57373"],

                labels: [this.$t('consolidatedStatement.chart.income'), this.$t('consolidatedStatement.chart.expenses')],
                tooltip: {
                    y: {
                        formatter: (value: number) =>
                            new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                            }).format(value),
                    },
                },
                legend: {
                    position: "bottom",
                },
                title: {
                    text: "",
                    align: "center",
                },
                chartSeries: [] as any, // Somas dos valores
            },



        }
    },
    methods: {
        generateReport() {
            const intervalo = Math.round(
                (new Date(this.endPeriod).getTime() -
                    new Date(this.startPeriod).getTime()) /
                (1000 * 3600 * 24)
            );

            if (intervalo > 31) {
                Snackbar.show(
                    "O intervalo de pesquisa não pode ser superior a 31 dias",
                    "error"
                );
                return false;
            }

            this.loadingDesserts = true;

            Overlay.show();

            Api.get("/v2/client/financeiro/consolidado", {
                params: {
                    startDate: this.startPeriod,
                    endDate: this.endPeriod,
                },
            })
                .then((response) => {


                    this.desserts = response.data.body;



                    const categories = (response.data.body as DataItem[]).map(
                        (item) =>
                            moment(item.periodDate).format("DD/MM/YYYY")
                    );
                    const creditData = (response.data.body as DataItem[]).map((item) =>
                        parseFloat(item.creditAmount)
                    )
                    const debitData = (response.data.body as DataItem[]).map((item) =>
                        Math.abs(parseFloat((item.debitAmount)))
                    );

                    this.chartOptionsLines.xaxis.categories = categories

                    this.chartOptionsLines.chartSeries = [
                        {
                            name: this.$t('consolidatedStatement.chart.income'),
                            data: creditData,
                        },
                        {
                            name: this.$t('consolidatedStatement.chart.expenses'),
                            data: debitData,
                        },
                    ];



                    // Calcular as somas
                    const totalCredit = response.data.body.reduce(
                        (sum: number, item: any) => sum + parseFloat(item.creditAmount),
                        0
                    );
                    const totalDebit = response.data.body.reduce(
                        (sum: number, item: any) => sum + Math.abs(parseFloat(item.debitAmount)),
                        0
                    );

                    // Atualizar o gráfico com os valores
                    this.chartOptionsDonut.chartSeries = [totalCredit,
                        totalDebit
                    ];


                    if (response.data.body.length > 0) {
                        this.hasData = true;
                    }

                    Overlay.hide();
                })
                .catch((error: any) => {
                    const response = error.response as AxiosResponse;
                    const status = response.status;
                    const data = response.data;

                    if (status === 422) {
                        this.errors = data.body.errors;
                    }
                })
                .finally(() => {
                    this.loadingDesserts = false;

                });
        },
        formatCurrency(value: string): string {
            const numberValue = parseFloat(value);
            return numberValue.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        }
    }
});

