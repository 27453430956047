
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { PerfilDadosbasicos } from "@/models/ProfileDadosBasicos";
import { Overlay } from "@/lib/Overlay";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import Swal from "sweetalert2";
import VueMask from "v-mask";
import router from "@/router";
import QsaList from './components/QsaList.vue'
Vue.use(VueMask);

export default Vue.extend({
  name: "PerfilDadosBasicos",
  components: {
    QsaList
  },
  data() {
    return {
      emailValidado: false,
      telefoneValidado: false,
      btnLoading: false,
      valid: true,
      loadingData: true,
      lang: navigator.language,
      errors: [],
      moment: moment,
      formEdit: {} as PerfilDadosbasicos,
      sexo: [
        { value: "M", label: "Masculino" },
        { value: "F", label: "Feminino" },
        { value: "NI", label: "Não Informado" },
      ],
    };
  },
  created() {
    this.showDadosBasicos();
  },

  methods: {
    showDadosBasicos() {
      Overlay.show();

      this.loadingData = true;
      Api.get("/v2/client/profile/show")
        .then((response) => {
          this.formEdit = response.data.body.dados;

          this.emailValidado = response.data.body.dados.emailValidado;
          this.telefoneValidado = response.data.body.dados.telefoneValidado;
          this.loadingData = false;
        })

        .finally(() => {
          Overlay.hide();
        });
    },

    onSubmit() {
      this.errors = [];
      this.btnLoading = true;
      Overlay.show();
      Api.put("/v2/client/profile/update", this.formEdit)
        .then((response) => {
          Snackbar.show("Dados atualizados com sucesso!");
          router.push("/perfil");
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 422) {
            this.errors = data.body.errors;
          } else {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
        })
        .finally(() => {
          this.btnLoading = false;
          Overlay.hide();
        });
    },
  },
});
