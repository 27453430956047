<template>
    <div>
      <v-data-table
        :headers="translatedHeaders"
        :items="qsaList"
        :loading="loading"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-btn
              color="purple darken-3"
              dark
              @click="openNewDialog"
            >
              <v-icon left>mdi-plus</v-icon>
              {{ $t("components.QsaList.pagetitle") }}
            </v-btn>
          </v-toolbar>
        </template>
<!--   
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template> -->
      </v-data-table>
  
      <v-dialog 
        v-model="dialog" 
        max-width="800px"
        fullscreen-breakpoint="600"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        persistent
        style="height: 600px"
        >
        <v-card >
          <v-card-title class="purple darken-3 white--text">
            {{ formTitle }}
          </v-card-title>
  
          <v-form ref="form" v-model="valid" @submit.prevent="save">
            <v-card-text>
              <v-container class="pr-0 pl-0">
                <v-row>
                  <v-col cols="12" sm="6" class="pt-0 pb-0">
                    <v-text-field
                        dense
                      v-model="editedItem.qsa_cpfcnpj"
                      :label="$t('components.QsaList.Form.Fields.document')"
                      outlined
                      v-mask="editedItem.qsa_cpfcnpj?.replace(/\D/g, '').length > 11 ? '##.###.###/####-##' : '###.###.###-##'"
                      :rules="[rules.required, rules.cpfCnpj]"
                      :error-messages="errors['qsa_cpfcnpj']"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0 pb-0">
                    <v-select
                      dense
                      v-model="editedItem.qsa_type"
                      :items="translatedTypeOptions"
                      :label="$t('components.QsaList.Form.Fields.type')"
                      maxlength="255"
                      outlined
                      :rules="[rules.required]"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" class="pt-0 pb-0">
                    <v-text-field
                      dense
                      v-model="editedItem.qsa_name"
                      :label="$t('components.QsaList.Form.Fields.name')"
                      maxlength="255"
                      outlined
                      :rules="[rules.required, rules.nome]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6" class="pt-0 pb-0">
                        <v-text-field
                        dense
                        v-model="editedItem.qsa_motherName"
                        :label="$t('components.QsaList.Form.Fields.mother_name')"
                        outlined
                        :rules="[rules.required, rules.motherName]"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="pt-0 pb-0">
                        <v-text-field
                        dense
                        v-model="editedItem.qsa_dateBirth"
                        :label="$t('components.QsaList.Form.Fields.birth_date')"
                        outlined
                        type="date"
                        :rules="[rules.required]"
                        ></v-text-field>
                    </v-col>
                    </v-row>

                    <v-row>
                    <v-col cols="12" sm="6" class="pt-0 pb-0">
                        <v-text-field
                        dense
                        v-model="editedItem.qsa_email"
                        :label="$t('components.QsaList.Form.Fields.email')"
                        maxlength="50"
                        outlined
                        :rules="[rules.email]"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="pt-0 pb-0">
                        <v-select
                        dense
                        v-model="editedItem.qsa_profile"
                        :items="translatedProfileOptions"
                        :label="$t('components.QsaList.Form.Fields.profile')"
                        outlined
                        :rules="[rules.required]"
                        ></v-select>
                    </v-col>
                    <v-col  class="pt-0 pb-0"> <v-checkbox
                        v-model="editedItem.qsa_isPep"
                        dense
                         class="pt-0 pb-0"
                        :label="$t('components.QsaList.Form.Fields.pep')"
                        ></v-checkbox></v-col>
                   
                </v-row>
                <v-col  class="pt-0 pb-0"><v-divider></v-divider></v-col>
                <v-row>
                    <v-col cols="12"  class="pt-0 pb-0">
                        <v-subheader class="pl-0">{{ $t('components.QsaList.Form.address_section') }}</v-subheader>
                    </v-col>
                    <v-col cols="12" sm="3" class="pt-0 pb-0">
                        <v-text-field
                        dense
                        v-model="editedItem.qsa_zip"
                        :label="$t('components.QsaList.Form.Fields.zip')"
                        maxlength="10"
                        outlined
                        v-mask="'#####-###'"
                        :rules="[rules.cep]"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="7" class="pt-0 pb-0">
                        <v-text-field
                        dense
                        v-model="editedItem.qsa_street"
                        :label="$t('components.QsaList.Form.Fields.street')"
                        maxlength="100"
                        outlined
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" class="pt-0 pb-0">
                        <v-text-field
                        dense
                        v-model="editedItem.qsa_number"
                        :label="$t('components.QsaList.Form.Fields.number')"
                        maxlength="10"
                        outlined
                        :rules="[rules.number]"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" class="pt-0 pb-0">
                        <v-text-field
                        dense
                        v-model="editedItem.qsa_neighborhood"
                        :label="$t('components.QsaList.Form.Fields.neighborhood')"
                        maxlength="40"
                        outlined
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" class="pt-0 pb-0">
                        <v-text-field
                        dense
                        v-model="editedItem.qsa_city"
                        :label="$t('components.QsaList.Form.Fields.city')"
                        maxlength="30"
                        outlined
                        :rules="[rules.city]"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" class="pt-0 pb-0">
                        <v-select
                        dense
                        v-model="editedItem.qsa_state"
                        :items="stateOptions"
                        :label="$t('components.QsaList.Form.Fields.state')"
                        outlined
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="2" class="pt-0 pb-0">
                        <v-select
                            dense
                            v-model="editedItem.qsa_country"
                            :items="countryOptions"
                            :label="$t('components.QsaList.Form.Fields.country')"
                            outlined
                            :value="'BRASIL'"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-col  class="pt-0 pb-0"><v-divider></v-divider></v-col>
                <v-row>
                    <v-col cols="12" class="pt-0 pb-0">
                      <v-subheader class="pl-0">{{ $t('components.QsaList.Form.phone_section')  }}</v-subheader>
                    </v-col>
                    <v-col cols="12" sm="2" class="pt-0 pb-0">
                        <v-text-field
                        dense
                        v-model="editedItem.qsa_phoneCountryCode"
                        :label="$t('components.QsaList.Form.Fields.country_code')"
                        maxlength="3"
                        outlined
                        v-mask="'##'"
                        value="55"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" class="pt-0 pb-0">
                        <v-text-field
                        dense
                        v-model="editedItem.qsa_phoneArea"
                        :label="$t('components.QsaList.Form.Fields.area_code')"
                        maxlength="3"
                        outlined
                        v-mask="'##'"
                        :rules="[rules.ddd]"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="5" class="pt-0 pb-0">
                      <v-text-field
                        dense
                        v-model="formattedPhoneNumber"
                        :label="$t('components.QsaList.Form.Fields.phone_number')"
                        outlined
                        :rules="[rules.phoneNumber]"
                        @input="val => editedItem.qsa_phoneNumber = val ? val.replace(/\D/g, '') : ''"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" class="pt-0 pb-0">
                        <v-select
                        dense
                        v-model="editedItem.qsa_phoneType"
                        :items="translatedPhoneTypeOptions"
                        :label="$t('components.QsaList.Form.Fields.phone_type')"
                        outlined
                        ></v-select>
                    </v-col>
                    </v-row>
                   
              </v-container>
            </v-card-text>
  <v-divider></v-divider>
            <v-card-actions>
              
              <v-spacer></v-spacer>
              <v-btn text @click="close">{{ $t('components.common.actions.Cancel')}}</v-btn>
              <v-btn
                color="purple darken-3"
                dark
                :loading="loading"
                type="submit"
              >
                {{ $t('components.common.actions.Save') }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import Vue from "vue";
  import ApiClient from "@/services/Api";
  import { Snackbar } from "@/lib/Snackbar";
  import Swal from "sweetalert2";
  import VueMask from "v-mask";

  Vue.use(VueMask);
  
  export default {
    name: "QsaList",
    data: () => ({
      formattedPhoneNumber: '',
      dialog: false,
      loading: false,
      valid: true,
      errors: [],
      qsaList: [],
      editedIndex: -1,
      editedItem: {
        qsa_cpfcnpj: "",
        qsa_type: "",
        qsa_name: "",
        qsa_profile: "",
        qsa_motherName: "",

        qsa_dateBirth: "",
        qsa_email: "",
        qsa_isPep: false,
        qsa_zip: "",
        qsa_street: "",
        qsa_number: "",
        qsa_neighborhood: "",
        qsa_city: "",
        qsa_state: "",
        qsa_country: "",
        qsa_phoneArea: "",
        qsa_phoneType: "",
        qsa_phoneCountryCode: "",
        qsa_phoneNumber: "",
        qsa_status: 1
      },
      defaultItem: {
        qsa_cpfcnpj: "",
        qsa_type: "",
        qsa_name: "",
        qsa_profile: "",
        qsa_motherName: "",
        qsa_dateBirth: "",
        qsa_email: "",
        qsa_isPep: false,
        qsa_zip: "",
        qsa_street: "",
        qsa_number: "",
        qsa_neighborhood: "",
        qsa_city: "",
        qsa_state: "",
        qsa_country: "BRASIL", 
        qsa_phoneArea: "",
        qsa_phoneType: "",
        qsa_phoneCountryCode: "55", 
        qsa_phoneNumber: "",
        qsa_status: 1
      },
    profileOptions: [
        { text: "Proprietário", value: "OWNER" },
       // { text: "Procurador", value: "ATTORNEY" },
    ],
        phoneTypeOptions: [
        { text: "Celular", value: "MOBILE" },
        { text: "Fixo", value: "LANDLINE" },
    ],
      headers: [
        { text: "Nome", value: "qsa_name" },
        { text: "CPF/CNPJ", value: "qsa_cpfcnpj" },
        { text: "Tipo", value: "qsa_type" },
        { text: "Ações", value: "actions", sortable: false },
      ],
      typeOptions: [
        { text: "Sócio Administrador", value: "MASTER" },
      //  { text: "Sócio", value: "REGULAR" },
      ],

      stateOptions: [
        { text: "Acre", value: "AC" },
        { text: "Alagoas", value: "AL" },
        { text: "Amapá", value: "AP" },
        { text: "Amazonas", value: "AM" },
        { text: "Bahia", value: "BA" },
        { text: "Ceará", value: "CE" },
        { text: "Distrito Federal", value: "DF" },
        { text: "Espírito Santo", value: "ES" },
        { text: "Goiás", value: "GO" },
        { text: "Maranhão", value: "MA" },
        { text: "Mato Grosso", value: "MT" },
        { text: "Mato Grosso do Sul", value: "MS" },
        { text: "Minas Gerais", value: "MG" },
        { text: "Pará", value: "PA" },
        { text: "Paraíba", value: "PB" },
        { text: "Paraná", value: "PR" },
        { text: "Pernambuco", value: "PE" },
        { text: "Piauí", value: "PI" },
        { text: "Rio de Janeiro", value: "RJ" },
        { text: "Rio Grande do Norte", value: "RN" },
        { text: "Rio Grande do Sul", value: "RS" },
        { text: "Rondônia", value: "RO" },
        { text: "Roraima", value: "RR" },
        { text: "Santa Catarina", value: "SC" },
        { text: "São Paulo", value: "SP" },
        { text: "Sergipe", value: "SE" },
        { text: "Tocantins", value: "TO" }
        ],
        countryOptions: [
            { text: "BRASIL", value: "BRASIL" }
  
        ],
     
    }),
    computed: {
      formTitle() {
        return this.editedIndex === -1 
            ? this.$t('components.QsaList.Form.new_title')
            : this.$t('components.QsaList.Form.edit_title');
      },
      phoneNumberMask() {
        console.log('Mask sendo calculada:', this.editedItem.qsa_phoneNumber);
        if (!this.editedItem.qsa_phoneNumber) return '####-####';
        const cleanNumber = this.editedItem.qsa_phoneNumber.replace(/\D/g, '');
        return cleanNumber.length > 8 ? '#####-####' : '####-####';
      },
      translatedProfileOptions() {
        return [
          { 
              text: this.$t('components.QsaList.Form.Fields.Options.profile.owner'), 
              value: "OWNER" 
          },
          { 
              text: this.$t('components.QsaList.Form.Fields.Options.profile.attorney'), 
              value: "ATTORNEY" 
          }
        ];
      },
      translatedTypeOptions() {
        return [
          { 
              text: this.$t('components.QsaList.Form.Fields.Options.type.master'), 
              value: "MASTER" 
          },
          { 
              text: this.$t('components.QsaList.Form.Fields.Options.type.regular'), 
              value: "REGULAR" 
          }
        ];
      },
      translatedPhoneTypeOptions() {
        return [
          { 
            text: this.$t('components.QsaList.Form.Fields.Options.phone.mobile'), 
            value: "MOBILE" 
          },
          { 
            text: this.$t('components.QsaList.Form.Fields.Options.phone.landline'), 
            value: "LANDLINE" 
          }
        ];
      },
      translatedHeaders() {
        return [
          { text: this.$t('components.QsaList.Table.headers.name'), value: "qsa_name" },
          { text: this.$t('components.QsaList.Table.headers.document'), value: "qsa_cpfcnpj" },
          { text: this.$t('components.QsaList.Table.headers.type'), value: "qsa_type" }
          //{ text: this.$t('components.QsaList.Table.headers.actions'), value: "actions", sortable: false }
        ];
      },
      rules() {
        return {
          required: v => !!v || this.$t('components.QsaList.Form.RulesMessage.required'),
          cpfCnpj: v => {
              if (!v) return true;
              const value = v.replace(/\D/g, '');
              if (value.length < 11) return true;
              if (value.length !== 11 && value.length !== 14) return this.$t('components.QsaList.Form.RulesMessage.invalid_document');
              return this.validateDocument(value) || this.$t('components.QsaList.Form.RulesMessage.invalid_document');
          },
          nome: v => {
              if (!v) return this.$t('components.QsaList.Form.RulesMessage.required');
              if (v.length < 3) return true;
              if (v.length > 100) return this.$t('components.QsaList.Form.RulesMessage.name_max_length');
              return /^[a-zA-ZÀ-ÿ\s]*$/.test(v) || this.$t('components.QsaList.Form.RulesMessage.name_letters_only');
          },
          email: v => {
              if (!v) return true;
              return /.+@.+\..+/.test(v) || this.$t('components.QsaList.Form.RulesMessage.invalid_email');
          },
          cep: v => {
              if (!v) return true;
              const cleanCep = v.replace(/\D/g, '');
              return cleanCep.length === 8 || this.$t('components.QsaList.Form.RulesMessage.invalid_zip');
          },
          ddd: v => {
              if (!v) return true;
              const cleanDDD = v.replace(/\D/g, '');
              return cleanDDD.length === 2 || this.$t('components.QsaList.Form.RulesMessage.invalid_area_code');
          },
          phoneNumber: v => {
              if (!v) return true;
              const cleanNumber = v.replace(/\D/g, '');
              if (cleanNumber.length !== 8 && cleanNumber.length !== 9) {
                  return this.$t('components.QsaList.Form.RulesMessage.invalid_phone');
              }
              if (cleanNumber.startsWith('0')) {
                  return this.$t('components.QsaList.Form.RulesMessage.invalid_phone');
              }
              if (cleanNumber.length === 9 && !cleanNumber.startsWith('9')) {
                  return this.$t('components.QsaList.Form.RulesMessage.invalid_mobile');
              }
              return true;    
          },
          motherName: v => {
              if (!v) return this.$t('components.QsaList.Form.RulesMessage.required');
              if (v.length < 3) return this.$t('components.QsaList.Form.RulesMessage.min_length');
              if (v.length > 100) return this.$t('components.QsaList.Form.RulesMessage.name_max_length');
              return /^[a-zA-ZÀ-ÿ\s]*$/.test(v) || this.$t('components.QsaList.Form.RulesMessage.name_letters_only');
          },
          number: v => {
              if (!v) return true; 
              if (v.length > 10) return this.$t('components.QsaList.Form.RulesMessage.number_max_length');
              return /^[0-9]+[a-zA-Z]?$|^[0-9]+[-\s]?[0-9]*[a-zA-Z]?$/.test(v) || this.$t('components.QsaList.Form.RulesMessage.number_alphanumeric');
          },
          city: v => {
              if (!v) return true; 
              if (v.length < 2) return this.$t('components.QsaList.Form.RulesMessage.city_min_length');
              if (v.length > 30) return this.$t('components.QsaList.Form.RulesMessage.city_max_length');
              return /^[a-zA-ZÀ-ÿ\s'-]*$/.test(v) || this.$t('components.QsaList.Form.RulesMessage.city_invalid_chars');
          },
        }
      }
    },
  
    mounted() {
      this.fetchData();
    },
  
    methods: {

      resetForm() {
        console.log('Chamou resetForm');
        this.editedItem = {
          ...this.defaultItem
        };
        if (this.$refs.form) {
          this.$refs.form.reset();
        }
        this.errors = [];
      },

      validateDocument(value) {
        const nums = value.replace(/\D/g, '').split('').map(Number);
        if (nums.length === 11) {
            if (nums.every(num => num === nums[0])) return false;
            let sum = 0;
            for (let i = 0; i < 9; i++) sum += nums[i] * (10 - i);
            let rev = 11 - (sum % 11);
            if (rev === 10 || rev === 11) rev = 0;
            if (rev !== nums[9]) return false;
            sum = 0;
            for (let i = 0; i < 10; i++) sum += nums[i] * (11 - i);
            rev = 11 - (sum % 11);
            if (rev === 10 || rev === 11) rev = 0;
            if (rev !== nums[10]) return false;
            
            return true;
        }                    
        if (nums.length === 14) {
            const peso1 = [5,4,3,2,9,8,7,6,5,4,3,2];
            let soma = 0;
            for(let i = 0; i < 12; i++) {
                soma += nums[i] * peso1[i];
            }
            const dv1 = soma % 11 < 2 ? 0 : 11 - (soma % 11);                   
            const peso2 = [6,5,4,3,2,9,8,7,6,5,4,3,2];
            soma = 0;
            for(let i = 0; i < 13; i++) {
                soma += nums[i] * peso2[i];
            }   
            const dv2 = soma % 11 < 2 ? 0 : 11 - (soma % 11);                
            return nums[12] === dv1 && nums[13] === dv2;
        }
        return false;
      },

      async fetchData() {
        this.loading = true;
        try {
          const response = await ApiClient.get("/v2/client/qsa/index");
          this.qsaList = response.data.body || [];
        } catch (error) {
          Snackbar.error(this.$t('components.QsaList.SnackbarMessage.error_loading'));
        } finally {
          this.loading = false;
        }
      },

      async editItem(item) {
        this.loading = true;
        try {
          
          const response = await ApiClient.get(`/v2/client/qsa/${item.id}/show`);          
          const dados = response.data.body[0]; 
          
          const newItem = {
            id: dados.id,
            ...dados,
            qsa_isPep: dados.qsa_isPep === "1", 
            qsa_phoneNumber: dados.qsa_phoneNumber.replace(/\D/g, ''),
            qsa_country: dados.qsa_country || "BRASIL",
            qsa_phoneCountryCode: dados.qsa_phoneCountryCode || "55",
            qsa_status: dados.qsa_status || 1
          };

          this.editedItem = newItem;
          this.editedIndex = this.qsaList.indexOf(item);  
          this.dialog = true;
        } catch (error) {
          Snackbar.error(this.$t('components.QsaList.SnackbarMessage.error_loading'));
        } finally {
          this.loading = false;
        }
      },
  
      async deleteItem(item) {
        const { isConfirmed } = await Swal.fire({
            title:  this.$t('components.QsaList.Swals.Delete.delete_title'),
            text: this.$t('components.QsaList.Swals.Delete.delete_text'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#673AB7',
            cancelButtonColor: '#757575',
            confirmButtonText: this.$t('components.QsaList.Swals.Delete.confirm_delete'),
            cancelButtonText: this.$t('components.common.actions.Cancel')
        });
        if (isConfirmed) {
          try {
          await ApiClient.delete(`/v2/client/qsa/${item.id}/delete`);
          await this.fetchData();
          Snackbar.show(this.$t('components.QsaList.SnackbarMessage.delete_success'));
          } catch (error) {
          Snackbar.error(this.$t('components.QsaList.SnackbarMessage.error_deleting'));
          }
        }
      },
  
      close() {
        this.dialog = false;
        this.$nextTick(() => {
          //this.resetForm();
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
          if (this.$refs.form) {
            this.$refs.form.reset();
          }
          this.errors = [];
        });
      },

      openNewDialog() {
        //this.resetForm();
        this.editedIndex = -1;
        this.dialog = true;
      },
  
      async save() {
        if (!this.$refs.form.validate()) return;
        this.loading = true;
        try {
          if (this.editedIndex > -1 && this.editedItem.id) {
            await ApiClient.put(
              `/v2/client/qsa/${this.editedItem.id}/update`,
              this.editedItem
            );
          } else {
            await ApiClient.post("/v2/client/qsa/store", this.editedItem);
          }
          await this.fetchData();
          Snackbar.show(this.$t('components.QsaList.SnackbarMessage.save_success'));
          this.close();

        } catch (error) {
            if (error.response?.data?.errors) {
                this.errors = error.response.data.errors;
            } else {
                Snackbar.error(this.$t('components.QsaList.SnackbarMessage.error_saving'));
            }
        } finally {
          this.loading = false;
        }
      },
      
    },
    watch: {
      '$i18n.locale'() {
          this.$nextTick(() => {
              if (this.$refs.form) {
                  this.$refs.form.resetValidation();
                  setTimeout(() => {
                      this.$refs.form.validate();
                  }, 100);
              }
          });
      },
      'editedItem.qsa_phoneNumber': {
        immediate: true,
        handler(newValue) {
          if (!newValue) {
            this.formattedPhoneNumber = ''; 
            return;
          }
          const cleanNumber = newValue.replace(/\D/g, '');
          if (cleanNumber.length > 8) {
            this.formattedPhoneNumber = cleanNumber.replace(/(\d{5})(\d{4})/, '$1-$2');
          } else {
            this.formattedPhoneNumber = cleanNumber.replace(/(\d{4})(\d{4})/, '$1-$2');
          }
        }
      }
    }
  };
  </script>